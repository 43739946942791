import.meta.glob([
  '../../images/**',
]);

const landingPage = () => import(/* webpackChunkName: "public.landing-page" */'@js/public/home/landing-page.js')
if (document.querySelector('#landingPage')) {
  landingPage().then(e => {
    e.default()
  })
}

const publicExperts = () => import(/* webpackChunkName: "public.experts" */'@js/public/expert/experts.js')
if (document.querySelector('#publicExperts')) {
  publicExperts().then(e => {
    e.default()
  })
}

const publicExpert = () => import(/* webpackChunkName: "public.expert" */'@js/public/expert/expert.js')
if (document.querySelector('#publicExpert')) {
  publicExpert().then(e => {
    e.default()
  })
}

const publicJobs = () => import(/* webpackChunkName: "public.jobs" */'@js/public/job/jobs.js')
if (document.querySelector('#publicJobs')) {
  publicJobs().then(e => {
    e.default()
  })
}

const publicCompanies = () => import(/* webpackChunkName: "public.companies" */'@js/public/company/companies.js')
if (document.querySelector('#publicCompanies')) {
  publicCompanies().then(e => {
    e.default()
  })
}

const publicCompany = () => import(/* webpackChunkName: "public.company" */'@js/public/company/company.js')
if (document.querySelector('#publicCompany')) {
  publicCompany().then(e => {
    e.default()
  })
}

const publicExpertDashboard = () => import(/* webpackChunkName: "public.expert.dashboard" */'@js/public/expert/dashboard/edit.js')
if (document.querySelector('#publicExpertDashboard')) {
  publicExpertDashboard().then(e => {
    e.default()
  })
}

const publicExpertDiscussion = () => import(/* webpackChunkName: "public.expert.discussion" */'@js/public/discussion/discussion.js')
if (document.querySelector('#publicExpertDiscussion')) {
  publicExpertDiscussion().then(e => {
    e.default()
  })
}

const publicClassroom = () => import(/* webpackChunkName: "public.classroom" */'@js/public/classroom/classroom.js')
if (document.querySelector('#publicClassroom')) {
  publicClassroom().then(e => {
    e.default()
  })
}

const publicTalentDashboard = () => import(/* webpackChunkName: "public.talent.dashboard" */'@js/public/talent/dashboard/edit.js')
if (document.querySelector('#publicTalentDashboard')) {
  publicTalentDashboard().then(e => {
    e.default()
  })
}

const publicPrivateChat = () => import(/* webpackChunkName: "public.private-chat" */'@js/public/private-chat/private-chat.js')
if (document.querySelector('#publicPrivateChat')) {
  publicPrivateChat().then(e => {
    e.default()
  })
}